import React, { useContext, useEffect, useState } from 'react';
import Site from '../layouts/Site';
import PageHeaderBar from '../components/Common/PageHeaderBar';
import PageFooter from '../components/Common/PageFooter';
import { AuthContext } from '../utils/AuthContext';
import * as amplitude from "@amplitude/analytics-browser";
import { CommonDataContext, CommonDataProvider } from '../utils/CommonDataContext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import HeroSummary from '../components/PlanDetails/HeroSummary';
import PlanDetailsBreakdown from '../components/PlanDetails/PlanDetailsBreakdown';
import SwitchNow from '../components/PlanDetails/SwitchNow';
import PostSwitch from '../components/PlanDetails/PostSwitch';
import { useLocation } from 'react-router-dom';
import { PseudoUserProfileContext } from '../utils/PseudoUserProfileContext';
import { AccordionContext } from '../utils/AccordionContext';
import * as AutomisedApis from '../utils/automisedAPIs'
import { SessionContext } from '../utils/SessionContext';


const PlanDetails = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const { authenticatedUser, userProfile } = useContext(AuthContext);
    const { sitePostcode, displayAddress } = useContext(CommonDataContext);
    const { planId } = useParams();
    const [comparisonPlansDetails, setComparisonPlansDetails] = useState(null);
    const [comparisonPlanDetailsAreLoading, setComparisonPlanDetailsAreLoading] = useState(false);
    const [errorLoadingData, setErrorLoadingData] = useState(false);
    const [costDivisor, setCostDivisor] = useState(null);
    const [eligibilityIsConfirmed, setEligibilityIsConfirmed] = useState(false);
    const { sessionContext, setSessionContext } = useContext(SessionContext);
    const defaultCostDivisor = 'yearly';
    const navigate = useNavigate();

    const costDivisorMap = {
        'yearly': 1,
        'quarterly': 4,
        'monthly': 12
    }

    //Extract query params
    const location = useLocation();
    const queryString = location.search;
    const queryParams = new URLSearchParams(queryString);
    const greenPower = queryParams.get('greenPower');

    useEffect(() => {
        setCostDivisor({ name: defaultCostDivisor, value: costDivisorMap[defaultCostDivisor] });
    }, [])

    useEffect(() => {
        console.debug(`Detected change to eligibility confirmation state: ${eligibilityIsConfirmed}`)
    }, [eligibilityIsConfirmed])

    useEffect(() => {
        amplitude.track(`PlanDetails page rendered`);

        console.log(`load comparison details ${userProfile}`)

        if (!comparisonPlanDetailsAreLoading && (userProfile)) {
            setComparisonPlanDetailsAreLoading(true);
            const consumerId = userProfile?.consumerId;
            const accountId = sessionContext.selectedProperty.accountId; //userProfile?.consumerId ? userProfile.defaultServicePoint.accountId : undefined;
            const servicePointId = sessionContext.selectedProperty.servicePointId; //userProfile?.consumerId ? userProfile.defaultServicePoint.servicePointId : undefined;

            if (!consumerId || !accountId || !servicePointId) return;

            AutomisedApis.getIndividualPlanCosts(consumerId, accountId, servicePointId, planId, { annualised: true })
                .then(response => {
                    const planDetails = response;
                    setComparisonPlansDetails(planDetails);
                    setErrorLoadingData(false);
                }).catch(error => {
                    setComparisonPlansDetails(null);
                    console.error('Error fetching plan details:', error);
                    setErrorLoadingData(true);
                })
                .finally(() => {
                    setComparisonPlanDetailsAreLoading(false);
                });

        }
    }, [userProfile])

    useEffect(() => {
        if (!comparisonPlansDetails || !comparisonPlansDetails[planId]?.electricityContract) return;
        setEligibilityIsConfirmed(!(comparisonPlansDetails[planId].electricityContract.eligibility && comparisonPlansDetails[planId].electricityContract.eligibility.length > 0));
    }, [comparisonPlansDetails])


    const setGranularity = (level) => {
        console.log(`Setting granularity level to ${level}`);
        setCostDivisor({ name: level, value: costDivisorMap[level] });
        /*
        switch(level) {
            case 'yearly': {
                setCostDivisor(1);
            } break;
            case 'quarterly': {
                setCostDivisor(4);
            } break;
            case 'monthly': {
                setCostDivisor(12);
            } break;
        };
        */
    }

    if (authenticatedUser && comparisonPlansDetails) {
        const targetPlanDetails = comparisonPlansDetails[planId];
        const currentPlan = comparisonPlansDetails.current_plan;

        console.debug(`Currentplan`)
        console.debug(currentPlan)


        const gotoDashboard = () => {
            navigate('/');
        }

        return (
            <AccordionContext.Provider value={{ selectedItem, setSelectedItem }}>
                <Site className='plan-details-page'>
                    <PageHeaderBar headerBarItem={{ icon: "arrow_back_long", descriptiveLabel: "Go back", className: "backNav", onClick: gotoDashboard }} />

                    <HeroSummary comparisonPlan={targetPlanDetails} displayAddress={displayAddress} currentPlanCosts={currentPlan.consumerCosts.total} greenPower={greenPower} costDivisorMap={costDivisorMap} costDivisor={costDivisor} setGranularity={setGranularity} eligibilityIsConfirmed={eligibilityIsConfirmed} />

                    <PlanDetailsBreakdown planDetails={targetPlanDetails} currentPlanCosts={currentPlan.consumerCosts.total} totalImports={(currentPlan.consumerCosts.referenceUsage.clImport || 0) + (currentPlan.consumerCosts.referenceUsage.generalImport || 0)} costDivisorMap={costDivisorMap} costDivisor={costDivisor} setGranularity={setGranularity} eligibilityIsConfirmed={eligibilityIsConfirmed} setEligibilityIsConfirmed={setEligibilityIsConfirmed} />

                    <SwitchNow planDetails={targetPlanDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} currentPlan={currentPlan} />

                    <PageFooter />
                </Site>
            </AccordionContext.Provider>
        );
    } else {
        return 'Loading...';
    }

};

export default PlanDetails;
