import React, { useState, useEffect, useContext } from 'react';


export const SessionContext = React.createContext();

export const SessionProvider = ({ children }) => {
    const [sessionContext, setSessionContext] = useState({});
  
    //Initialise selected property to undefined, ready to be set within a component
    useEffect(() => {
      setSessionContext({
        selectedProperty: {
          accountId: undefined,
          servicePointId: undefined
        }
      })
    },[]);

    return (
      <SessionContext.Provider value={{ sessionContext, setSessionContext }}>
        {children}
      </SessionContext.Provider>
    );
  };