import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './design/design-system.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/Common/Errors';
import { AwsRumProvider } from './utils/awsRumContext';

//Supress debug logging in production environment
if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
    console.debug = () => {};
    console.log = () => {}; // Similarly, overwrite other console methods if needed
  }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AwsRumProvider>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </AwsRumProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
