import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { SessionContext } from './SessionContext';
import { UnsupportedDemandChargesException, UnsupportedPostcodeException } from './Exceptions';


export const updateUserProfile = async (consumerId, userProfile) => {
    try {
        const API_URL = `${process.env.REACT_APP_API_URL}consumers/${consumerId}/profile`;
        const response = await axios.put(API_URL, userProfile);
        return response.data;
    } catch (error) {
        //Handle error
        console.error('Unable to update user profile. ', error);
        return error;
    }
}

export const getBillingPortalURL = async (consumerId) => {
    try {
        const API_URL = `${process.env.REACT_APP_API_URL}consumers/${consumerId}/billing/portal`;
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        //Handle error
        console.error('Unable to get billing portal URL for consumer. ', error);
        return error;
    }
}

export const startStripeCustomerSession = async (consumerId) => {
    try {
        const API_URL = `${process.env.REACT_APP_API_URL}consumers/${consumerId}/billing/session`;
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        //Handle error
        console.error('Unable to get Stripe session for consumer. ', error);
        return error;
    }
}

export const getCurrentPlan = async (consumerId, accountId, servicePointId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/plan`)
        .then(response => {
            const currentPlanData = response.data;
            return currentPlanData;
        }).catch(error => {
            console.error('Error fetching info on current plan: ', error);
            throw new Error(`Error fetching info on current plan: ${error}`)
        })
        .finally(() => {
        });
}

export const getComparisonPlanCosts = async (consumerId, accountId, servicePointId, options) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/plan-costs?annualised=true&f=${options.excludePlansWithEligibility ? 'eligibility' : ''}&dedupe=true`)
        .then(response => {
            let comparisonPlansData = response.data;
            comparisonPlansData = comparisonPlansData.filter(plan => plan.planId != 'actual_costs'); //Remove actual costs plan. Not used for app.
            return comparisonPlansData;
        }).catch(error => {
            //If response status == 501, handle it here
            if (error.response && error.response.status === 501) {
                console.warn('Feature not implemented: ', error.response.data.message);
                // Handle the 501 error specifically
                //return []; // or any other appropriate fallback
                switch (error.response.data.error) {
                    case 'UnsupportedPostCodeException': {
                        throw new UnsupportedPostcodeException(error.response.data.message);
                        break;
                    }
                    case 'DemandChargesNotSupported': {
                        throw new UnsupportedDemandChargesException(error.response.data.message);
                        break;
                    }
                    default: {
                        throw new Error(error.response.data.message);
                        break;
                    }
                }
            } else {
                //Otherwise, throw an error
                console.error('Error fetching info on comparison plans costs: ', error);
                throw new Error(`Error fetching info on comparison plans costs: ${error}`)
            }
        })
        .finally(() => {
        });
}

export const getIndividualPlanCosts = async (consumerId, accountId, servicePointId, planId, options) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/plan-costs/${encodeURIComponent(planId)}?annualised=true`)
        .then(response => {
            const currentPlanData = response.data;
            return currentPlanData;
        }).catch(error => {
            console.error('Error fetching info on current plan: ', error);
            throw new Error(`Error fetching info on current plan: ${error}`)
        })
        .finally(() => {
        });
}

export const getBillingData = async (consumerId, accountId, servicePointId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/bills`)
        .then(response => {
            const billingData = response.data;
            return billingData;
        }).catch(error => {
            console.error('Error fetching billing data from API: ', error);
            throw new Error(`Error fetching billing data from API: ${error}`)
        })
        .finally(() => {
        });
}

export const getPublicPlanDetails = async (planId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}plans/${planId}`)
        .then(response => {
            const publicPlanDetails = response.data;
            return publicPlanDetails;
        }).catch(error => {
            console.error('Error fetching public plan data from API: ', error);
            throw new Error(`Error fetching public plan data from API: ${error}`)
        })
        .finally(() => {
        });
}

export const initCdrAgreement = async () => {
    console.debug(`POSTing to Automised Energy API /cdr/agreement to initialise a new CDR agreement for the authenticated user`)
    return axios.post(`${process.env.REACT_APP_API_URL}cdr/agreement`)
        .then(response => {
            const agreementDetails = response.data;
            return agreementDetails;
        }).catch(error => {
            console.error('Error initialising CDR agreement with API: ', error);
            throw new Error(`Error initialising CDR agreement with API: ${error}`)
        })
        .finally(() => {
        });
}

export const getCdrAgreementDh = async (agreementId) => {
    console.debug(`GETing from Automised Energy API /cdr/agreement/{agreementId}/dh to find details of DH of the CDR agreement for the authenticated user`)
    return axios.get(`${process.env.REACT_APP_API_URL}cdr/agreement/${agreementId}/dh`)
        .then(response => {
            const dhInfo = response.data;
            return dhInfo;
        }).catch(error => {
            console.error('Error getting DH info from CDR agreement with API: ', error);
            throw new Error(`Error getting DH info from CDR agreement with API: ${error}`)
        })
        .finally(() => {
        });
}

export const finaliseOnboarding = async (agreementId) => {
    console.debug(`PUTing to Automised Energy API /consumers/finalise-onboarding to finalise onboarding with CDR agreement ${agreementId} for the authenticated user`)
    return axios.put(`${process.env.REACT_APP_API_URL}consumers/finalise-onboarding`, { agreementId })
        .then(response => {
            const agreementDetails = response.data;
            return agreementDetails;
        }).catch(error => {
            console.error(`Error finalising CDR agreement ${agreementId} with API: `, error);
            throw new Error(`Error finalising CDR agreement ${agreementId} with API: ${error}`)
        })
        .finally(() => {
        });
}

export const getCdrAgreementStatus = async (agreementId) => {
    console.debug(`Sending GET request to Automised Energy API /cdr/agreement-status to retrieve status of the CDR agreement ${agreementId} for the authenticated user`)
    return axios.get(`${process.env.REACT_APP_API_URL}cdr/agreement-status/${agreementId}`)
        .then(response => {
            const agreementStatus = response.data;
            return agreementStatus;
        }).catch(error => {
            console.error(`Error getting CDR agreement status of ${agreementId} with API: `, error);
            throw new Error(`Error getting CDR agreement status of ${agreementId} with API: ${error}`)
        })
        .finally(() => {
        });
}

export const cancelAccount = async (consumerId) => {
    console.debug(`Sending DELETE request to Automised Energy API /consumer to cancel and delete the account for the authenticated user`)
    return axios.delete(`${process.env.REACT_APP_API_URL}consumers/${consumerId}`)
        .then(response => {
            const agreementStatus = response.data;
            return agreementStatus;
        }).catch(error => {
            console.error(`Error cancelling Automised Energy account with API: `, error);
            throw new Error(`Error cancelling Automised Energy account with API: ${error}`)
        })
        .finally(() => {
        });
}
