import { useContext, useEffect, useState } from 'react';
import './GetStarted.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';
import Icon from '../Common/Icon';
import { Button } from '@aws-amplify/ui-react';
import { HintButton } from '../Common/Hints';
import * as AutomisedAPIs from '../../utils/automisedAPIs';
import { useNavigate } from 'react-router-dom';

export const GetStartedWithBiza = ({ status }) => {
    const { flowStep, gotoNextStep, gotoPrevStep, progressBarSteps } = useContext(OnboardingContext);
    const [ctaBusy, setCtaBusy] = useState(false)
    const [consentError, setConsentError] = useState(undefined);
    const [dhInfo, setDhInfo] = useState({ brandName: 'Retailer', logoUri: undefined });
    const [byLine, setByLine] = useState(`To use our services, let's:`)
    const [title, setTitle] = useState(`Let's see how much you could save`)
    const [button, setButton] = useState(`Get started`)

    useEffect(() => {
        // Check for error parameter in URL
        const queryParams = new URLSearchParams(window.location.search);
        const errorParam = queryParams.get('error');

        if (errorParam) {
            /*
            const agreementId = queryParams.get('agreementId');
            const dhInfo = AutomisedAPIs.getCdrAgreementDh(agreementId);
            setDhInfo(dhInfo);*/

            const errorObj = {
                label: errorParam,
                description: queryParams.get('errorDescription'),
                uri: queryParams.get('errorUri')
            }
            setConsentError(errorObj);

            setTitle(`Error getting permissions`);
            setByLine(`There's been an error while getting your permissions (error description: ${errorObj.description}). Not to worry, let's try again. We need to:`);
            setButton(`Try again`);
        } else { //No error found
            if (status === 'incompleteConsent') {
                setTitle(`Ready to cut your power bills?`);
                setByLine(`Hmmm... it looks like we haven't got your permission yet. No worries, let's pick up where we left off. We need to:`);
                setButton(`Let's go!`);
            }
        }
    }, []);

    const launchCdrConsentFlow = () => {
        setCtaBusy(true);

        //init agreement
        const newAgreement = AutomisedAPIs.initCdrAgreement()
            .then((newAgreementDetails) => {
                console.debug(`New agreement details: ${JSON.stringify(newAgreementDetails)}`)
                //redirect to agreement flow url
                const redirectUri = newAgreementDetails.data.redirectUri;
                //Redirect here:
                window.location.href = redirectUri;
            })
            .catch((error) => {
                setCtaBusy(false);
            })
            .finally(() => {
                //setCtaBusy(false);
            })
    }

    return (
        <div className='flowStepContent'>
            <div className='heading'>
                <h1 className='headline'>{title}</h1>
                <span className='byline'>{byLine}</span>
            </div>
            <div className='getStartedSteps'>
                <div className='getStartedStep'>
                    <span className='stepNumber'>1.</span>
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            Get your permission
                        </h2>
                        <p className='stepDescription'>
                            for us to collect and analyse your electricity data
                        </p>
                    </div>
                </div>
                <div className='getStartedStep'>
                    <span className='stepNumber'>2.</span>
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            Give permission
                        </h2>
                        <p className='stepDescription'>
                            to your provider to share your electricity data with us
                        </p>
                    </div>
                </div>
                <div className='getStartedStep'>
                    <span className='stepNumber'>3.</span>
                    <div className='stepContent'>
                        <h2 className='stepName'>
                            View savings
                        </h2>
                        <p className='stepDescription'>
                            available from your best-fit plans
                        </p>
                    </div>
                </div>
            </div>
            <div className='cdrMention'>
                <p className='message'>
                    Secure data sharing via the Consumer Data Right (CDR)
                    <HintButton topic='cdrConsent' />
                </p>
            </div>
            <div className='nav'>
                <button onClick={launchCdrConsentFlow} className={`primary next ${ctaBusy ? 'busy' : ''}`}>{button}</button>
            </div>
        </div>
    )
};