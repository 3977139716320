import React, { useContext, useEffect, useState } from 'react';
import './Collecting.scss';
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';

export const Collecting = () => {
    const { selectedProvider } = useContext(OnboardingContext);

    // Array of messages to cycle through
    const messages = [
        'Collecting account info',
        'Collecting usage history',
        'Collecting plan details',
        'Collecting meter details',
        'Collecting previous bills',
        'Almost done',
        "Don't refresh"
    ];

    // State to keep track of the current message index
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        // Function to update the message index
        const updateMessage = () => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        };

        // Set interval to change message every 5 seconds
        const intervalId = setInterval(updateMessage, 5000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [messages.length]);

    return (
        <div className='flowStepContent collectionStep'>
            <div className='valuePropImage'>
                <span className="fade-text">{messages[currentMessageIndex]}<span className='flashing-ellipsis' /></span>
                
                <div className='loader slow' />
            </div>
            <div className='heading'>
                <h1 className='headline'>Collecting data</h1>
                <span className='byline'>Your data is on its way from <strong>{selectedProvider}</strong>. Not long now to discover how much you could save!</span>
            </div>
        </div>
    );
};
