import { useContext, useEffect, useState } from 'react';
import './ValueProps.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';
import Icon from '../Common/Icon';

const countOfValuePropScreens = 3;

export const ValueProp1 = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    const title = "Welcome to Automised Energy!";

    const listItems = [
        'Reduce your power bill',
        'Get valuable insights',
        'Save money, energy & time'
    ];

    return (
        <ValueProp image="moneyPots.png" imageAlt="Money pots" title={title} listItems={listItems} position="1" />
    )
};

export const ValueProp2 = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    const title = "Find your best-fit electricity plan";

    const listItems = [
        'Independently, across all providers',
        'Know how much you can save',
        'No ads, no sales calls'
    ];

    return (
        <ValueProp image="paperPlane.png" imageAlt="Paper aeroplane" title={title} listItems={listItems} position="2" />
    )

};

export const ValueProp3 = () => {
    const { flowStep, gotoNextStep, gotoPrevStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    //const title = "Get valuable insights";
    const title = "Find all savings for free";

    /*const listItems = [
        'Understand your current plan',
        'View previous bills',
        'All in one place'
    ];*/
    const listItems = [
        'Free to discover how much you can save',
        'Subscribe for details of big savings'
    ];

    return (
        <ValueProp image="flower.png" imageAlt="Flower" title={title} listItems={listItems} position="3" />
    )

};

const ValueProp = ({ image, imageAlt, title, listItems, position }) => {
    const { flowStep, gotoNextStep, gotoPrevStep, gotoGetStarted } = useContext(OnboardingContext);

    return (
        <div className='flowStepContent'>
            <div className='carouselContent'>
                <img src={`/assets/images/${image}`} className='valuePropImage' alt={imageAlt} />
                <h1 className='headline'>
                    {title}
                </h1>
                <ul>
                    {listItems.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            <div className='carouselProgress'>
                {new Array(countOfValuePropScreens).fill().map((_, index) => {
                    if ((index + 1) == position) {
                        return (
                            <div key={index} className='indicatorDot currentDot' />
                        )
                    } else {
                        return (
                            <div key={index} className='indicatorDot' />
                        )
                    }
                }
                )}
            </div>
            <div className='nav'>
                <button onClick={gotoNextStep} className='primary next'>Next</button>
                
            </div>
        </div>
    )
}
