export class UnsupportedPostcodeException extends Error {
    constructor(message) {
      super(message);
      this.name = 'UnsupportedPostcodeException';
    }
  }

  export class UnsupportedDemandChargesException extends Error {
    constructor(message) {
      super(message);
      this.name = 'UnsupportedDemandChargesException';
    }
  }  

  export class InsufficientUserAttributesException extends Error {
    constructor(message) {
      super(message);
      this.name = 'InsufficientUserAttributesException';
    }
  }