import React, { useContext, useEffect, useState } from 'react';
import { CommonDataContext } from '../../utils/CommonDataContext';
import './ProfileContent.scss';
import Button from '../Common/Button';
import * as AutomisedAPI from '../../utils/automisedAPIs';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';

export const ProfileContent = () => {
    const { userProfile, authenticatedUser } = useContext(CommonDataContext)
    const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);

    const handleAccountCancel = () => {
        setOpenConfirmCancelModal(true);
    };

    const handleModalClose = (evt) => {
        if (evt.detail.action === 'yes') {
            console.log("Cancelling Automised Energy account");
            AutomisedAPI.cancelAccount(userProfile.consumerId)
                .then(() => {
                    setOpenConfirmCancelModal(false);
                    window.location = '/logout';
                })
                .catch((err) => {
                    console.error(err);
                    window.alert('Error while trying to cancel account. Please contact support@automised.energy');
                    setOpenConfirmCancelModal(false);
                })
        } else {
            setOpenConfirmCancelModal(false);
        }
    };


    return <>
        <div className="content-tile primary profile-tile">
            <h1>Your profile</h1>

            <div className="profile-section personal-information">
                <h2>Account</h2>
                <div className="profile-section-content">
                    <div className="profile-section-content-part">
                        <label className='profile-label'>First name</label>
                        <span className='profile-value'>{authenticatedUser.attributes.given_name}</span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Last name</label>
                        <span className='profile-value'>{authenticatedUser.attributes.family_name}</span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>Email</label>
                        <span className='profile-value'>{authenticatedUser.attributes.email}</span>
                    </div>
                    <div className="profile-section-content-part">
                        <label className='profile-label'>ID Source</label>
                        <span className='profile-value'>{authenticatedUser.attributes.identities[0].providerName}</span>
                    </div>
                </div>
            </div>
        </div>

        <div className='profile-tile your-data content-tile'>
            <div className="profile-section">
                <h2>Your data</h2>
                <div className="profile-section-content">
                    <div className="profile-section-content-part">
                        <p>Your data has been securely collected from your electricity provider, and is used with your permission in accordance with the Australian Consumer Data Right regulation and the Australian Privacy laws, under a Representative arrangement with Biza Pty Ltd. For more details <a href="https://policy.biza.io/docs/cdr/" target="_blank">see Biza's Consumer Data Right policy</a> and Automised Energy's <a href='https://www.automised.energy/app-privacy-policy'>Automised Energy's Privacy Policy</a>.</p>
                    </div>
                </div>
                <div className="section-action">
                    <Button href={process.env.REACT_APP_CDR_DASHBOARD_URL} target="_blank" type="secondary" label="Manage your data permissions" />
                </div>
            </div>
        </div>

        <div className='profile-tile personal-information content-tile'>
            <div className="profile-section">
                <h2>Cancel your Automised Energy account</h2>
                <p>This will cancel all data sharing permissions between your provider and Automised Energy, and will delete your Automised Energy account including all data collected by Automised Energy that is directly associated with you. Without an active Automised Energy account, we will not be able to analyse and find the best-fit electricity plan for you.</p>
                <p>This does <strong>not</strong> affect your home's electricity service.</p>
                <div className='section-action'>
                    <Button href="#" label="Cancel Automised Energy Account" type="secondary" className="danger" icon="report" clickHandler={handleAccountCancel} />
                </div>
            </div>
            <Dialog open={openConfirmCancelModal} onClose={handleModalClose} className='hintDialog'>
                <DialogTitle className='hintTitle'>Confirm</DialogTitle>
                <DialogContent className='hintContent'>
                    <p>Are you sure you want to cancel your Automised Energy account?</p>
                </DialogContent>
                <DialogActions className='hintActions'>
                    <DialogButton ripple={false} action="no" className='hintButton' isDefaultAction={true}>No</DialogButton>
                    <DialogButton ripple={false} action="yes" className='hintButton'>Yes</DialogButton>
                </DialogActions>
            </Dialog>
        </div>
    </>
}