import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import * as amplitude from "@amplitude/analytics-browser";
import * as AutomisedApis from './automisedAPIs'
import { UnsupportedDemandChargesException, UnsupportedPostcodeException } from './Exceptions';
import { SessionContext } from './SessionContext';



export const DataContext = React.createContext();

export function DataProvider(props) {
  const { authenticatedUser, userProfile } = useContext(AuthContext);

  const [planData, setPlanData] = useState([]); // you can set an initial value or fetch it
  const [postcode, setPostcode] = useState(null); // you can set an initial value or fetch it
  const [currentPlan, setCurrentPlan] = useState({ consumerCosts: { total: 0 } }); // you can set an initial value or fetch it
  const [servicePoint, setServicePoint] = useState(null); // you can set an initial value or fetch it
  const [usageProfile, setUsageProfile] = useState(null); // you can set an initial value or fetch it
  const { sessionContext, setSessionContext } = useContext(SessionContext);

  const [planDataIsLoading, setPlanDataIsLoading] = useState(false);
  const [postcodeDataIsLoading, setPostcodeDataIsLoading] = useState(false);
  const [currentPlanDataIsLoading, setCurrentPlanDataIsLoading] = useState(false);
  const [servicePointDataIsLoading, setServicePointDataIsLoading] = useState(false);
  const [usageProfileDataIsLoading, setUsageProfileDataIsLoading] = useState(false);

  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const [errorLoadingPostcodeData, setErrorLoadingPostcodeData] = useState(false);
  const [errorLoadingCurrentPlanData, setErrorLoadingCurrentPlanData] = useState(false);
  const [errorLoadingServicePointData, setErrorLoadingServicePointData] = useState(false);
  const [errorLoadingUsageProfileData, setErrorLoadingUsageProfileData] = useState(false);

  const [planSortBy, setPlanSortBy] = useState('price');
  const [dashView, setDashView] = useState('cost');
  const [displayAddress, setDisplayAddress] = useState('');

  const [isUnsupportedPostcode, setIsUnsupportedPostcode] = useState(false);
  const [hasNoOpenAccounts, setHasNoOpenAccounts] = useState(false);
  const [hasDemandCharges, setHasDemandCharges] = useState(false);
  

  var dataIsLoading = false;
  var loadCount = 0;

  useEffect(() => {
    console.log(`From Dashboard: Authenticated user profile: ${JSON.stringify(userProfile)}`);
  }, [])

  useEffect(() => {
    if (((userProfile && Object.keys(userProfile).length === 0 && userProfile.constructor === Object) || !userProfile)) return;

    const consumerId = userProfile?.consumerId;
    const accountId = sessionContext.selectedProperty.accountId; //userProfile?.consumerId ? 'defaultAccountIdDDC':'';//userProfile?.defaultServicePoint?.accountId : undefined;
    const servicePointId = sessionContext.selectedProperty.servicePointId; //userProfile?.consumerId ? 'defaultSPDDC':'';//userProfile?.defaultServicePoint?.servicePointId : undefined;
    const servicePointError = userProfile?.consumerId ? userProfile?.defaultServicePoint?.error : undefined;

    if (servicePointError === "NOOPENACCOUNTS") { setHasNoOpenAccounts(true); return; }
    if (!consumerId) { setErrorLoadingData(true); return; }
    if (!accountId) { setErrorLoadingData(false); return; }
    if (!servicePointId) { setErrorLoadingData(false); return; }

    loadCount++;

    if (loadCount > 1) return;
    // fetch data or perform any other side effects here
    // example:
    // fetchDataFromAPI().then(fetchedData => setData(fetchedData));
    if (!planDataIsLoading) {
      setPlanDataIsLoading(true);
      const oldPlanSortBy = planSortBy;
      setPlanSortBy(undefined); //Reset the plan sort by key
      AutomisedApis.getComparisonPlanCosts(consumerId, accountId, servicePointId, { annualised: true, excludePlansWithEligibility: false })
        .then(response => {
          const planCostData = response;
          setPlanData(planCostData);
          setErrorLoadingData(false);
          setPlanSortBy(oldPlanSortBy); //Trigger the sorting
        }).catch(error => {
          if (error instanceof UnsupportedPostcodeException) {
            setIsUnsupportedPostcode(true);
          } else if (error instanceof UnsupportedDemandChargesException) {
            setHasDemandCharges(true);
          } else {
            setPlanData('Error fetching plan details. See server logs for more details');
            console.error('Error fetching plan data:', error);
            setErrorLoadingData(true);
          }
        })
        .finally(() => {
          setPlanDataIsLoading(false);
          //stopLoadingSpinner('planBlock');
        });
    }

    if (!postcodeDataIsLoading) {
      setPostcodeDataIsLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/postcode`)
        .then(response => {
          const postcodeData = response.data;
          setPostcode(postcodeData);
          setErrorLoadingPostcodeData(false);
        }).catch(error => {
          console.error('Error fetching postcode:', error);
          setErrorLoadingPostcodeData(true);
        })
        .finally(() => {
          setPostcodeDataIsLoading(false);
          //stopLoadingSpinner('planBlock');
        });
    }

    if (!currentPlanDataIsLoading) {
      setCurrentPlanDataIsLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}/plan`)
        .then(response => {
          const currentPlanData = response.data;
          setCurrentPlan(currentPlanData);
          setErrorLoadingCurrentPlanData(false);
        }).catch(error => {
          console.error('Error fetching plan info:', error);
          setErrorLoadingCurrentPlanData(true);
        })
        .finally(() => {
          setCurrentPlanDataIsLoading(false);
          //stopLoadingSpinner('planBlock');
        });
    }

    if (!servicePointDataIsLoading) {
      setServicePointDataIsLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/accounts/${accountId}/service-points/${servicePointId}`)
        .then(response => {
          const servicePointData = response.data;
          setServicePoint(servicePointData);
          setErrorLoadingServicePointData(false);

          // Site address from servicePoint data
          var address = "";
          if (servicePointData?.location.addressUType == "paf") {
            const spLocation = servicePointData.location.paf;
            address = (spLocation.flatUnitType ? spLocation.flatUnitType : '')
              + (spLocation.flatUnitNumber ? spLocation.flatUnitNumber + '/' : '')
              + (spLocation.thoroughfareNumber1 ? spLocation.thoroughfareNumber1 : '')
              + (spLocation.thoroughfareNumber1Suffix ? spLocation.thoroughfareNumber1Suffix + ' ' : ' ')
              + (spLocation.thoroughfareNumber2 ? '- ' + spLocation.thoroughfareNumber2 : '')
              + (spLocation.thoroughfareNumber2Suffix ? spLocation.thoroughfareNumber2Suffix + ' ' : ' ')
              + (spLocation.lotNumber && !spLocation.thoroughfareNumber1 ? spLocation.lotNumber + ' ' : '')
              + toProperCase(spLocation.streetName ? spLocation.streetName + ' ' : '')
              + toProperCase(spLocation.streetType)// ? spLocation.streetType + ', ' : '')
            //+ toProperCase(spLocation.localityName ? spLocation.localityName + ', ' : '')
            //+ (spLocation.postcode ? spLocation.postcode + ', ' : '')
            //+ (spLocation.state || '').toUpperCase();
          } else if (servicePointData?.location.addressUType == "simple") {
            const spLocation = servicePointData.location.simple;
            address = (spLocation.addressLine1 ? spLocation.addressLine1 + ', ' : '')
              + toProperCase(spLocation.addressLine2 ? spLocation.addressLine2 + ', ' : '')
              + toProperCase(spLocation.addressLine3)// ? spLocation.addressLine3 + ', ' : '')
            //+ toProperCase(spLocation.city ? spLocation.city + ', ' : '')
            //+ (spLocation.postcode ? spLocation.postcode + ', ' : '')
            //+ (spLocation.state || '').toUpperCase();
          }

          setDisplayAddress(address);
        }).catch(error => {
          console.error('Error fetching service point info:', error);
          setErrorLoadingServicePointData(true);
        })
        .finally(() => {
          setServicePointDataIsLoading(false);
          //stopLoadingSpinner('planBlock');
        });
    }

    if (!usageProfileDataIsLoading) {
      setUsageProfileDataIsLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${consumerId}/usage/profiles?servicePointId=${servicePointId}`)
        .then(response => {
          const usageProfileData = response.data;
          setUsageProfile(usageProfileData);
          setErrorLoadingUsageProfileData(false);
        }).catch(error => {
          console.error('Error fetching usage profile info:', error);
          setErrorLoadingUsageProfileData(true);
        })
        .finally(() => {
          setUsageProfileDataIsLoading(false);
          //stopLoadingSpinner('planBlock');
        });
    }

    //setPlanSortBy(planSortBy); //Trigger the sorting

    function toProperCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }, [userProfile, sessionContext.selectedProperty]);

  useEffect(() => {
    if (!planData || !planSortBy) {
      //console.log(`Asked to sort the plan data, but not plan data was found`);
      return;
    }

    amplitude.track(`List of better plans sorted by ${planSortBy}`);

    let sortedPlans = [...planData]; // Clone the original array

    if (planSortBy == 'price') {
      console.debug(`Sorting plans by price...`)
      sortedPlans.sort((a, b) => { //Sort plans lowest to highest on total consumer costs
        if (!a.consumerCosts) return 1;  // a is larger if property is missing
        if (!b.consumerCosts) return -1; // b is larger if property is missing

        return a.consumerCosts.total - b.consumerCosts.total;
      });
    } else
      if (planSortBy == 'greenPower') {
        console.debug(`Sorting plans by greenPower, then price...`)
        sortedPlans.sort((a, b) => { //Sort plans highest to lowest on greenPower available, then by price
          if (!a.greenPower) return -1;  // b is larger if property is missing
          if (!b.greenPower) return 1; // a is larger if property is missing

          if (b.greenPower.maxAvailable == a.greenPower.maxAvailable) {
            return (a.consumerCosts.total + a.greenPower.maxCost) - (b.consumerCosts.total + b.greenPower.maxCost); //Sort by price (inc greenPower costs), lowest to highest, if there is a match on maximum greenPower available
          } else {
            return b.greenPower.maxAvailable - a.greenPower.maxAvailable;
          }

        });
      }

    setPlanData(sortedPlans);

  }, [planSortBy])

  dataIsLoading = planDataIsLoading || postcodeDataIsLoading || currentPlanDataIsLoading || servicePointDataIsLoading || usageProfileDataIsLoading;
  let hasLoadingError = errorLoadingData || errorLoadingPostcodeData || errorLoadingCurrentPlanData || errorLoadingServicePointData || errorLoadingUsageProfileData;


  if (isUnsupportedPostcode) {
    return <>
      <div id="pageHeaderBar"></div>
      <div className='content-tile'>
        <h1>Unsupported postcode</h1>
        <p>Unfortunately we're not quite ready for you yet! While we're rolling out our sevices as far and as fast as we can, we currently can't support your area.</p>
        <p>We'll be rolling out fairly quickly, so we'll advise when we're available for you.</p>
      </div>
    </>
  } else if (hasNoOpenAccounts) {
    return <>
      <div id="pageHeaderBar"></div>
      <div className='content-tile'>
        <h1>No open accounts</h1>
        <p>Unfortunately we could not find any open accounts in the data you asked your retailer to share with us. For us to analyse your acount data, we need access to an account that is currently open.</p>
        <p>If you did share an open account with us, please contact us at <a href="mailto:support@automised.energy">support@automised.energy</a>.</p>
      </div>
    </>
  } else if (hasDemandCharges) {
    return <>
      <div id="pageHeaderBar"></div>
      <div className='content-tile'>
        <h1>Demand charges found</h1>
        <p>We detected that your current plan includes demand charges. Demand charges are additional charges per month that are based on the highest recorded spike in your usage throughout each month. </p>
        <p>Unfortunately, providers do not yet supply enough data to allow Automised Energy to calculate estimated costs where the plan includes demand charges. We are actively pushing for improved data that will allow us to support demand charges in the near future.</p>
        <p>Follow Automised Energy on LinkedIn, Facebook or Instagram to learn when support is added.</p>
      </div>
    </>
  } else if (hasLoadingError) {
    amplitude.track(`Error loading data`, { errorLoadingData, errorLoadingPostcodeData, errorLoadingCurrentPlanData, errorLoadingServicePointData, errorLoadingUsageProfileData });
    //throw new Error(`Error loading data for dashboard.`)
    return (
      <>
        <div id="pageHeaderBar"></div>
        <div className='content-tile'>
          <h1>Hang tight!</h1>
          <p>We are still processing your data. Your data is complicated and involves the help of a human. Check back again later to see what savings have been found!</p>
        </div>
      </>
    )
  }

  return (
    <DataContext.Provider value={{ planData, planSortBy, setPlanSortBy, postcode, currentPlan, servicePoint, dataIsLoading, usageProfile, dashView, setDashView, energySavingSuggestions, displayAddress }}>
      {props.children}
    </DataContext.Provider>
  );
}

const energySavingSuggestions = {
  count: 6,
  tailored: [
    {
      topic: 'Solar Power (demo)',
      subTopic: 'Undersized',
      image: '3d-bell.svg',
      actionSummary: 'Adding another 5kW of solar power will save you around 8,125kWh of energy (~$436) per year ',
      details: {
        description: "Your solar power system is not as big as it could be. The Essential Energy network that you are connected to allows residential inverters of up to 10kW at your location. Your current inverter has a capacity of 5kW. Based on your usage profile, an additional 5kW output would reduce your annual energy usage by around 8,348kWh, reduce your bill by about $436 per year, deliver an additional 13,245kWh of renewable energy to the grid per year and save 143 tons of CO2 from entering the atmosphere every year. We have a 5⭐ solar installer in your postcode that is able to provide a 4.5⭐ 10kW system for around $10,000*.",
        actions: [
          { label: "More details", url: "/suggestion/1234" },
          { label: "Request a quote", url: "/suggestion/2345/quote" }
        ]
      }
    },
    {
      topic: 'Controlled Load (demo)',
      subTopic: 'Change mode',
      image: '3d-bell.svg',
      actionSummary: 'Changing your hot water system from Controlled Load 2 to Controlled Load 1 would save you around 8,125kWh of energy (~$436) per year ',
      details: {
        description: "Your electric hot water system is being powered from a Controlled Load circuit (formally 'off-peak'). Your Controlled Load circuit is set to mode '2' (10 - 18 hours every day). Having it set to mode '1' (5 - 9 hours every weekday, more on weekends) would reduce your energy usage by around 7,347kWh per year, your bill by around $398 per year and prevent 98 tons of CO2 emissions from entering the atmosphere every year. This change can be done by contacting your retailer (Origin Energy) on 1800 123 123. See the 'More Details' section for a script for the phone call as well as considerations for this suggested change.",
        actions: [
          { label: "More details", url: "/suggestion/1234" }
        ]
      }
    },
    {
      topic: 'Hot water (demo)',
      subTopic: 'Power from solar panels',
      image: '3d-bell.svg',
      actionSummary: 'Changing your hot water system from the overnight Controlled Load circuit to the mid-day general circuit would save you around 8,125kWh of energy (~$436) per year ',
      details: {
        description: "Your electric hot water system is being powered from a Controlled Load circuit (formally 'off-peak') overnight, every night (costing you 17c/kWh), and you are producing excess solar energy every day (earning you 7c/kWh). Changing your hot water system to be powered by the solar power during the day with a timer instead of the Controlled Load during the night would reduce your energy usage by around 7,347kWh per year, your bill by around $398 per year and prevent 98 tons of CO2 emissions from entering the atmosphere every year. This change can be done by contacting your preferred electrician. See the 'More Details' section for a script for considerations for this suggested change.",
        actions: [
          { label: "More details", url: "/suggestion/1234" }
        ]
      }
    },
    {
      topic: 'Solar Power (demo)',
      subTopic: 'Underperforming',
      image: '3d-bell.svg',
      actionSummary: 'Check and clean your panels. This would save you around 4,125kWh of energy (~$236) per year',
      details: {
        description: "Your solar power system is performing at only 54% of what is expected for your location. Restoring its performance to expected levels (>75%) would reduce your annual energy usage by around 4,125kWh, reduce your bill by about $236 per year, deliver an additional 13,245kWh of renewable energy to the grid per year and save 143 tons of CO2 from entering the atmosphere every year. Check for any damage to panels, give them a clean with a hose and soft broom (or harsher is liechin is growin on them) and check for shadows from branches or other objects that could be removed to maximise light.",
        actions: [
          { label: "More details", url: "/suggestion/1234" }
        ]
      }
    }

  ],
  general: [
    {
      topic: 'Lights (demo)',
      subTopic: 'LED',
      image: '3d-bell.svg',
      actionSummary: 'Replacing 10 old light bulbs in your house would save around 2,125kWh of energy (~$66) per year ',
      details: {
        description: "Old style electric light bulbs (incandescent) are very ineffecient, producing more heat than light. For every 10 60W incandescent bulbs replaced, you would reduce your annual energy usage by around 2,348kWh, reduce your bill by about $436 per year and save 143 tons of CO2 from entering the atmosphere every year.",
        actions: [
          { label: "More details", url: "/suggestion/1234" }
        ]
      }
    },
    {
      topic: 'Hot water systems (demo)',
      subTopic: 'Heat pump',
      image: '3d-bell.svg',
      actionSummary: 'Upgrading your hot water system to a modern heat pump will save you around 2,125kWh of energy (~$236) per year ',
      details: {
        description: "Electric element hot water systems are an inefficient method of heating a tank full of water, compared to using a heat pump. Replacing your aging electric element hot water tank with a modern efficient heat pump would reduce your annual energy usage by around 8,348kWh, reduce your bill by about $436 per year and save 143 tons of CO2 from entering the atmosphere every year. We have a 5⭐ solar installer in your postcode that is able to provide a 4.5⭐ 1.2kW system for around $5,000*.",
        actions: [
          { label: "More details", url: "/suggestion/1234" },
          { label: "Request a quote", url: "/suggestion/2345/quote" }
        ]
      }
    }

  ]
}