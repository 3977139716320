import React, { useEffect } from 'react';

const EmbeddedBrowserRedirect = () => {
  useEffect(() => {
    function isEmbeddedBrowser() {
      const ua = window.navigator.userAgent.toLowerCase();
      return (
        ua.includes('fban') || // Facebook App
        ua.includes('fbav') || // Facebook App (older versions)
        ua.includes('instagram') ||
        ua.includes('twitter') ||
        ua.includes('linkedinapp') ||
        ua.includes('line') ||
        ua.includes('wechat') ||
        ua.includes('miuibrowser') || // Xiaomi's embedded browser
        (ua.includes('android') && ua.includes('wv')) // Android WebView
      );
    }

    function handleRedirect() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const currentUrl = window.location.href;

      console.debug(`Checking if user agent is embedded browser.`)
      if (!isEmbeddedBrowser()) {
        console.debug(`User agent does not look like an embedded browser`)
        return;
      }

      console.debug(`User agent looks to be an embedded browser. Attemping to redirect to full browser.`)
      if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
        // iOS devices
        const iosSafariUrl = 'x-safari-' + currentUrl;
        console.debug(`Detected iOS. Redirecting to ${iosSafariUrl}`)
        window.location.href = iosSafariUrl;
      } else if (userAgent.includes('android')) {
        // Android devices
        // Try the intent URL scheme
        const androidUrl = 'intent://' + currentUrl.replace(/^https?:\/\//, '') + '#Intent;scheme=https;end';
        console.debug(`Detected Android. Redirecting to ${androidUrl}`)
        window.location.href = androidUrl;
      }
    }

    handleRedirect();
  }, []);

  return null; // This component doesn't render anything
};

export default EmbeddedBrowserRedirect;